import React, { useState, useEffect } from "react"

import ApplicationsManagement from "../../components/Applications/ApplicationsManagement"
import ApplicationsTable from "../../components/Applications/ApplicationsTable"

import SuccessNotice from "../../components/SuccessNotice"
import ErrorNotice from "../../components/ErrorNotice"

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"
import { AiOutlineFileAdd } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"

import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"
import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs"
import IconButton from "../../components/ui/buttons/IconButton"
import ApplicationsExport from "../../components/Applications/ApplicationsExport"
import ApplicationsImport from "../../components/Applications/ApplicationsImport"
import WarningNotice from "../../components/WarningNotice"
import Modal from "../../components/Modal"


const ApplicationsPage = ({ 
	fetched, 
	loading,
	getApplication, 
	newApplication, 
	editApplication,
	application,
	applicationPerPage,
	successMessage,
	errorMessage,
	clearSuccessMessage,
	clearErrorMessage,
	access,
	bpRelations,
	getBPRelations
}) => {
	const [active, setActive] = useState('Applications')

	const [success, setSuccess] = useState('')
	const [error, setError] = useState('')
	const [warning, setWarning] = useState('')
	const [errorField, setErrorField] = useState('')

	const [addChosen, setAddChosen] = useState(false)
	const [updateChosen, setUpdateChosen] = useState(false)

	const [showDelete, setShowDelete] = useState(false)
	const [deleteItemsType, setDeleteItemsType] = useState('applicationsArray')
	
	const [name, setName] = useState('');  
	const [tag, setTag] = useState('') 
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [link, setLink] = useState('')
	const [description, setDescription] = useState('')

	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState('')
	const [updateTag, setUpdateTag] = useState('') 
	const [updateStartDate, setUpdateStartDate] = useState('')
	const [updateEndDate, setUpdateEndDate] = useState('')
	const [updateLink, setUpdateLink] = useState('')
	const [updateDescription, setUpdateDescription] = useState('')
	
	const [current, setCurrent] = useState(0)
	const [paginationLevels, setPaginationLevels] = useState(applicationPerPage.length)

	const { Application_Add, Application_Delete } = access.Buttons
	
	useEffect(() => {
		getApplication()
		getBPRelations()
	}, [])

	const clearFields = () => {
		setName('')
		setTag('')
		setStartDate('')
		setEndDate('')
		setLink('')
	}

	const deleteAllApplications = () => {
		setShowDelete(true)
		setDeleteItemsType('applicationsArray')
	}

	const addData = () => {
		if (!name) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000);
			return
		}
		newApplication({
			name,
			start_date: startDate,
			end_date: endDate,
			tag,
			link,
			description
		})
		.then(() => {
			setAddChosen(false);
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateName) {
			setError("Name is required");
			setErrorField('name')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);
			return
		}
		editApplication({
			id: updateId,
			name: updateName,
			start_date: updateStartDate,
			end_date: updateEndDate,
			tag: updateTag,
			link: updateLink,
			description: updateDescription
		})
		.then(() => {
			setUpdateChosen(false)
		})
	}

	return (
		<>
			<div className="notices">
					{(success || successMessage) && <SuccessNotice message={success || successMessage} clearMessage={clearSuccessMessage} />}
					{warning && <WarningNotice message={warning} />}
					{(error || errorMessage) && <ErrorNotice message={error || errorMessage} clearMessage={clearErrorMessage} />}
			</div>
			{loading || !fetched ? 
				<SkeletonTabs /> :
				<ApplicationsManagement active={active} setActive={setActive} access={access.Tabs}/>
			}
			{active === 'Applications' && 
				<>
					{showDelete && (
						<Modal
							setShowDelete={setShowDelete}
							deleteItemType={deleteItemsType}
							deleteItems={application}
						/>
					)}
					{addChosen && (
						<div>
							<div className="top-inputs">
								<div>
									<p>Name</p>
										<input
										value={name}
										onChange={(e) => setName(e.target.value)}
										placeholder="Name"
										type="text"
										id="name"
										autoFocus
										name="name"
										className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96"}
									/>
								</div>
								<div>
									<p>Link</p>
										<input
										value={link}
										onChange={(e) => setLink(e.target.value)}
										placeholder="Link"
										type="text"
										id="link"
										name="link"
										className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96"
									/>
								</div>
								<div>
									<p>Tag</p>
										<input
										value={tag}
										onChange={(e) => setTag(e.target.value)}
										placeholder="Tag"
										type="text"
										id="tag"
										name="tag"
										className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-76 mr-4"
									/>
								</div>
							</div>
							<div className="top-inputs">
							<div className="1-1/4">
									<p>Start Date</p>
									<input
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
										type="date"
										id="start-date"
										name="start-date"
										className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
									/>
								</div>
								<div className="1-1/4">
									<p>End Date</p>
									<input
										value={endDate}
										onChange={(e) => setEndDate(e.target.value)}
										type="date"
										id="end-date"
										name="end-date"
										className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
									/>
								</div>
								<div>
									<p>Note</p>
										<input
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										placeholder="Note"
										type="text"
										id="description"
										name="description"
										className={"bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
									/>
								</div>
							</div>
							<div className="w-3/4 flex justify-between align-middle">
								<button
									onClick={() => {
										setAddChosen(false);
									}}
									className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										addData();
									}}
									className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
								>
									Add Application
								</button>
							</div>
						</div>
					)}
					{updateChosen && (
						<div>
							<div className="top-inputs">
								<div>
									<p>Name</p>
										<input
										value={updateName}
										onChange={(e) => setUpdateName(e.target.value)}
										placeholder="Name"
										type="text"
										id="name"
										autoFocus
										name="name"
										className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96"}
									/>
								</div>
								<div>
									<p>Link</p>
										<input
										value={updateLink}
										onChange={(e) => setUpdateLink(e.target.value)}
										placeholder="Link"
										type="text"
										id="link"
										name="link"
										className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96"
									/>
								</div>
								<div>
									<p>Tag</p>
										<input
										value={updateTag}
										onChange={(e) => setUpdateTag(e.target.value)}
										placeholder="Tag"
										type="text"
										id="tag"
										name="tag"
										className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-76 mr-4"
									/>
								</div>
							</div>
							<div className="top-inputs">
							<div className="1-1/4">
									<p>Start Date</p>
									<input
										value={updateStartDate}
										onChange={(e) => setUpdateStartDate(e.target.value)}
										type="date"
										id="start-date"
										name="start-date"
										className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
									/>
								</div>
								<div className="1-1/4">
									<p>End Date</p>
									<input
										value={updateEndDate}
										onChange={(e) => setUpdateEndDate(e.target.value)}
										type="date"
										id="end-date"
										name="end-date"
										className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
									/>
								</div>
								<div>
									<p>Note</p>
										<input
										value={updateDescription}
										onChange={(e) => setUpdateDescription(e.target.value)}
										placeholder="Note"
										type="text"
										id="description"
										name="description"
										className={"bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
									/>
								</div>
							</div>
							<div className="w-3/4 flex justify-between align-middle">
								<button
									onClick={() => {
										setUpdateChosen(false);
									}}
									className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										updateData();
									}}
									className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
								>
									Update Application
								</button>
							</div>
						</div>
					)}
					<div className="tables">
						<div className="top-table">
							<div className="plus-minus">
								<IconButton
									className="icon-img"
									onClick={() => {
										setUpdateChosen(false);
										setAddChosen(!addChosen);
									}}
									notRestricted={Application_Add}
									stateActive={addChosen}
									tooltip={'Add new'}
								>
									<AiOutlineFileAdd alt="add" />
								</IconButton>	
								
							</div>
							<div className="plus-minus ml-5">
								<IconButton
										className="icon-img"
										onClick={deleteAllApplications}
										notRestricted={Application_Delete && (application && application.length)}
										stateActive={showDelete}
										tooltip={'Delete all table data'}
									>
										<MdDeleteForever />
								</IconButton>
							</div>
							<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
							<div
								className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
								onClick={() => {
									if (current > 0) {
										setCurrent(current - 1);
									}
								}}
							>
								<img src={Left} alt="left" />
							</div>

							<div
								className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
								onClick={() => {
									if (current < paginationLevels - 2) {
										setCurrent(current + 1);
									}
								}}
							>
								<img src={Right} alt="right" />
							</div>
						</div>
						</div>
						<div className="table-div">
						{loading || !fetched ? 
								<SkeletonTable /> :
								<ApplicationsTable
									data={applicationPerPage}
									deletedChosen={false}
									setSelected={null}
									setUpdateId={setUpdateId}
									setSuccess={setSuccess}
									setError={setError}
									getData={getApplication}
									setUpdateChosen={setUpdateChosen}
									setAddChosen={setAddChosen}
									setUpdateName={setUpdateName}
									setUpdateStartDate={setUpdateStartDate}
									setUpdateEndDate={setUpdateEndDate}
									setUpdateTag={setUpdateTag}
									setUpdateLink={setUpdateLink}
									setUpdateDescription={setUpdateDescription}

									current={current}
									setPaginationLevels={setPaginationLevels}

									setShowDelete={setShowDelete}
									
									access={access.Buttons}
								/>
						}
						</div>
					</div>
				</>
			}
			{active === 'Export' && <ApplicationsExport dataArray={application} access={access.Buttons} dataArrayBP={bpRelations} />}
			{active === 'Import' && <ApplicationsImport dataArray={application} access={access.Buttons} setWarning={setWarning} setError={setError} setSuccess={setSuccess}/>}
		</>
	)
}

export default ApplicationsPage

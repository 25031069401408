import React, {useEffect, useMemo, useState} from 'react'
import { Navigate } from 'react-router-dom'
import FormInput from '../components/ui/inputs/FormInput'
import FacebookSpinner from '../components/ui/spinners/FacebookSpinner'
import SuccessNotice from '../components/SuccessNotice'
import ErrorNotice from '../components/ErrorNotice'

const Recover = ({ forgotPassword, token, fetched, loading, errorMessage, successMessage, clearErrorMessage, clearSuccessMessage }) => {
  const [values, setValues] = useState({
      email: ""
  })

  if (token) return <Navigate to="/"/>    

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address",
      label: "Email",
      required: true,
    },

  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ email: values.email })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  if (!fetched || loading) {
    return (
      <div className="spinner-container">
        <FacebookSpinner />
      </div>
      )
  }

  return (
    <div className="sign-up mt-10">
      {successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage} />}
      {/* {warning && <WarningNotice message={warning} />} */}
      {errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage} />}
      <form className="form" onSubmit={handleSubmit}>
        <h4 className='form__title'>Send reset password link to </h4>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className='form__button'>Submit</button>
        <p>Don't have an account?</p>
        <a href="/signup">Sign up here</a>
      </form>
    </div>
  );

}

export default Recover
import React, { useEffect } from 'react'


const AboutPage = ({}) => {


	useEffect(() => {
	}, [])

	return (
		<div className="about-page__container">  
			<h1 className="about-page__container__title">About Us</h1>
			<div className="about-page__container__content">
				<p>This application is developed by a small team of experienced IT professionals. It was conceived as a tool to help the enterprise architect work. Work on the idea and the requirements began in 2019. Technical development began in October 2022.</p>
				<p>The team consists of several IT experts who emigrated from Russia in 2022 and a developer from Ukraine. By our example, we prove the possibility of friendship and cooperation between people of different nationalities, despite the confrontation between our countries.</p>
				<p>We invite you to cooperate:</p>
				<ul>
					<li>If you want to help in the development of this product, take part in testing or want to offer your idea</li>
					<li>If you need help in software development (full cycle, microservices, backend - Golang, frontend - TypeScript, JavaScript, React)</li>
				</ul>
				<p className="mt-10 ">e-mail: <span className="font-semibold second-color">info@transitrix.com</span></p>
			</div>
		</div>
	)
}

export default AboutPage
import React, { useState, useEffect } from "react"
import BusinessManagement from "../../components/Business/BusinessManagement"
import BusinessTable from "../../components/Business/BusinessTable"
import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"
import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs"
import BusinessRelations from "../../components/Business/BusinessRelations"
import IconButton from "../../components/ui/buttons/IconButton"
import BusinessExport from "../../components/Business/BusinessExport"
import BusinessImport from "../../components/Business/BusinessImport"
import ProcessesReport from "../../components/Reports/ProcessesReport/ProcessesReport"
import Modal from "../../components/Modal"

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"
import { AiOutlineFileAdd, AiOutlineClose } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"


const BusinessPage = ({ 
	fetched, 
	loading,
	getBusiness, 
	newBusiness, 
	editBusiness,
	business,
	businessPerPage,
	getApplication,
	capabilityType,
	capabilityTypeType,
	getCapabilityType,
	getCapabilityTypeType,
	application,
	relationship,
	bpRelations,
	bpRelationsPerPage,
	getBPRelations,
	newBPRelations,
	editBPRelations,
	successMessage,
	errorMessage,
	clearSuccessMessage,
	clearErrorMessage,
	clearWarningMessage,
	newErrorMessage,
	userOrganization,
	getOrganization,
	organization,
	access
}) => {
	const [active, setActive] = useState('Process')

	const [success, setSuccess] = useState('')
	const [error, setError] = useState('')
	const [warning, setWarning] = useState('')
	const [errorField, setErrorField] = useState('')

	const [addChosen, setAddChosen] = useState(false)
	const [updateChosen, setUpdateChosen] = useState(false)

	const [showDelete, setShowDelete] = useState(false)
	const [deleteItemsType, setDeleteItemsType] = useState('processesArray')

	const [formActive, setFormActive] = useState(false)

	const [name, setName] = useState('');  
	const [property, setProperty] = useState(capabilityTypeType.length ? capabilityTypeType[0].name : '')
	const [tag, setTag] = useState('') 
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [link, setLink] = useState('')

	const [applicationObject, setApplicationObject] = useState(application.length ? application[0] : {})
	const [applicationRelationshipStartDate, setApplicationRelationshipStartDate] = useState('')
	const [applicationRelationshipEndDate, setApplicationRelationshipEndDate] = useState('')
	const [relationshipName, setRelationshipName] = useState(relationship.length ? relationship[0] : {})
	const [applications, setApplications] = useState([])

	const [updateApplicationsWithNewArray, setUpdateApplicationsWithNewArray] = useState([])
	const [updateApplications, setUpdateApplications] = useState([])

	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState('')
	const [updateProperty, setUpdateProperty] = useState('')
	const [updateTag, setUpdateTag] = useState('')
	const [updateStartDate, setUpdateStartDate] = useState('')
	const [updateEndDate, setUpdateEndDate] = useState('')
	const [updateLink, setUpdateLink] = useState('')

	const [deleteItem, setDeleteItem] = useState('')
	const [deleteId, setDeleteId] = useState('')
	
	const [current, setCurrent] = useState(0)
	const [paginationLevels, setPaginationLevels] = useState(businessPerPage.length)

	const { Processes_Add, Processes_Delete } = access.Buttons
	
	useEffect(() => {
		getCapabilityType()
		getCapabilityTypeType()
		getBusiness()
		getApplication()
		getBPRelations()
		getOrganization({id: userOrganization})
	}, [])

	useEffect(() => {
		setProperty(capabilityTypeType.length ? capabilityTypeType[0].name : '')
		setApplicationObject(application.length ? application[0] : {})
		setRelationshipName(relationship.length ? relationship[0] : {})
	}, [capabilityTypeType, application, relationship])

	useEffect(() => {
		// if deleting confirmed update the applications
		setUpdateApplications(updateApplicationsWithNewArray)
	}, [bpRelations])

	const applicationOnChangeHandler = (e) => {
		const appObject = application.find(item => item.id === Number(e.target.value))
		setApplicationObject(appObject)
	}

	const getRelationshipId = (r) => { 
		const relationshipObj = relationship.find(item => item.name === r)
		
		return relationshipObj ? relationshipObj.id : -1
	}

	const clearFields = () => {
		setName('')
		setProperty(capabilityTypeType.length ? capabilityTypeType[0].name : '')
		setTag('')
		setStartDate('')
		setEndDate('')
		setLink('')
		setApplicationObject(application.length ? application[0] : {})
		setRelationshipName(relationship.length ? relationship[0] : {})
		setApplicationRelationshipStartDate('')
		setApplicationRelationshipEndDate('')
		setApplications([])
	}

	const deleteAllProcesses = () => {
		setShowDelete(true)
		setDeleteItemsType('processesArray')
	}

	const getPropertyId = (property) => {
		const selectedObject = capabilityTypeType.find(item => item.name === property)

		return selectedObject ? selectedObject.order : -1
	}

	const relationshipChangeHandler = (e) => {
		const relationshipItem = relationship.find(item => item.id === Number(e.target.value))
		setRelationshipName(relationshipItem)
	}

	const applicationRelationshipAddHandler = (application, start_date, end_date, relationshipItem) => {
		const newItem = {application, start_date, end_date, relationship_dict: relationshipItem}
		setApplications([...applications, newItem])
	}

	const updateApplicationRelationshipAddHandler = (application, start_date, end_date, relationshipItem) => {
		const newItem = {application, start_date, end_date, relationship_dict: relationshipItem}
		setUpdateApplications([...updateApplications, newItem])
	}
	
	const applicationRelationshipDeleteHandler = (app, applicationsArray, setMethod) => {
		const deleteAppIndex = applicationsArray.indexOf(app)
		const newAppsArray = JSON.parse(JSON.stringify(applicationsArray))
		newAppsArray.splice(deleteAppIndex, 1)
		setMethod(newAppsArray)
	}
	
	const updateApplicationRelationshipDeleteHandler = (app, applicationsArray) => {
		if (app.id) { // existing relationship in a database
			setDeleteItemsType('bprelation')
			setDeleteItem(app)
			setDeleteId(app.id)
			setShowDelete(true)
	
			const deleteAppIndex = applicationsArray.indexOf(app)
			const newAppsArray = JSON.parse(JSON.stringify(applicationsArray))
			newAppsArray.splice(deleteAppIndex, 1)
			setUpdateApplicationsWithNewArray(newAppsArray)
			return
		}

		applicationRelationshipDeleteHandler(app, applicationsArray, setUpdateApplications)
	}

	const applicationRelationshipChangeHandler = (oldItem, e) => {
		const newRelationship = relationship.find(item => item.id === Number(e.target.value))
		applications[applications.indexOf(oldItem)].relationship_dict = newRelationship
		setApplications([...applications])
	}

	const applicationRelationshipStartDateChangeHandler = (oldItem, e) => {
		applications[applications.indexOf(oldItem)].start_date = e.target.value
		setApplications([...applications])
	}

	const applicationRelationshipEndDateChangeHandler = (oldItem, e) => {
		applications[applications.indexOf(oldItem)].end_date = e.target.value
		setApplications([...applications])
	}

	const updateApplicationRelationshipChangeHandler = (oldItem, e) => {
		const newRelationship = relationship.find(item => item.id === Number(e.target.value))
		updateApplications[updateApplications.indexOf(oldItem)].relationship_dict = newRelationship
		setUpdateApplications([...updateApplications])
	}

	const updateApplicationRelationshipStartDateChangeHandler = (oldItem, e) => {
		updateApplications[updateApplications.indexOf(oldItem)].start_date = e.target.value
		setUpdateApplications([...updateApplications])
	}

	const updateApplicationRelationshipEndDateChangeHandler = (oldItem, e) => {
		updateApplications[updateApplications.indexOf(oldItem)].end_date = e.target.value
		setUpdateApplications([...updateApplications])
	}

	const createRelationships = (bp_list_id, applicationsArray) => {
		for (const relation of applicationsArray) {
			newBPRelations({
				application_id: relation.application.id,
				bp_list_id,
				relationship_dict_id: relation.relationship_dict.id,
				start_date: relation.start_date,
				end_date: relation.end_date,
			})
		}
	}

	const addData = () => {
		if (!name) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000)
			return
		}
		newBusiness({
			name: name,
			property_id: getPropertyId(property),
			start_date: startDate,
			end_date: endDate,
			tag: tag,
			link: link,
			relationships: applications
		})
		.then(() => {
			setAddChosen(false);
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateName) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000)
			return
		}
		editBusiness({
			id: updateId,
			name: updateName,
			property_id: getPropertyId(updateProperty),
			start_date: updateStartDate,
			end_date: updateEndDate,
			tag: updateTag,
			link: updateLink,
		})
		.then(() => {
			createRelationships(updateId, updateApplications)
			setUpdateChosen(false)
		})
	}

	return (
		<>
		{loading || !fetched ? 
			<SkeletonTabs /> :
			<BusinessManagement active={active} setActive={setActive} access={access.Tabs} />
		}
		{active === 'Process' && 
			<>
			{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteItemType={deleteItemsType}
					deleteItems={business}
					deleteId={deleteId}
					deleteItem={deleteItem}
				/>
			)}
			{addChosen && (
				<div className="">
					<div className="">
						<div className="top-inputs">
							<div>
							<p>Name</p>
								<input
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Name"
								type="text"
								id="name"
								autoFocus
								name="name"
								className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96"}
							/>
							</div>
							<div>
								<p>Process Level</p>
								<select
									value={property}
									onChange={(e) => setProperty(e.target.value)}
									className="bg-white h-11 w-72 shadow rounded py-2 px-3"
									name="property"
									id="property"
								>
									{capabilityTypeType.map((item) => {
										const empty = item ? false : true
										return (
										<option disabled={empty} value={item.name} key={item.level}>
											{item.name}
										</option>
										)
									})}
								</select>
							</div>
							<div>
							<p>Link</p>
								<input
								value={link}
								onChange={(e) => setLink(e.target.value)}
								placeholder="Link"
								type="text"
								id="link"
								name="link"
								className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
							/>
							</div>
						</div>
						<div className="top-inputs">
						<div className="1-1/4">
							<p>Start Date</p>
							<input
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
								type="date"
								id="start-date"
								name="start-date"
								className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							/>
							</div>
							<div className="1-1/4">
							<p>End Date</p>
							<input
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								type="date"
								id="end-date"
								name="end-date"
								className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							/>
							</div>
							<div>
								<p>Tag</p>
									<input
									value={tag}
									onChange={(e) => setTag(e.target.value)}
									placeholder="Tag"
									type="text"
									id="tag"
									name="tag"
									className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-72"
								/>
							</div>
						</div>
					</div>
					{/* <div className="mr-4 mt-6">
						<p>Connected Applications</p>
						<div className="form-application-container">
							{formActive ?
								<div className="h-11 shadow rounded py-2 px-3 form-application-relationship">
									<button className="form-activity__button-close" onClick={() => setFormActive(false)}><span>Close</span><AiOutlineClose /></button>
									<div className="flex items-end m-2">
										<div>
											<p>Application</p>
											<select												
												onChange={applicationOnChangeHandler}
												className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
												name="relationship"
											>
												{application.length && application.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})}
											</select>
										</div>
										<div>
											<p>Relationship</p>
											<select
												value={relationshipName.id}
												onChange={relationshipChangeHandler}
												className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
												name="relationship"
											>
												{relationship.length &&
													relationship.map((item) => {
														return (
															<option value={item.id} key={item.id}>
																{item.name}
															</option>
														)
												})}
											</select>
										</div>	
										<div>
											<p>Start Date</p>
											<input
												value={applicationRelationshipStartDate}
												onChange={(e) => setApplicationRelationshipStartDate(e.target.value)}
												type="date"
												name="relationship-date"
												className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
										<div>
											<p>End Date</p>
											<input
												value={applicationRelationshipEndDate}
												onChange={(e) => setApplicationRelationshipEndDate(e.target.value)}
												type="date"
												name="relationship-date"
												className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
										<button className="text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg" onClick={() => applicationRelationshipAddHandler(applicationObject, applicationRelationshipStartDate, applicationRelationshipEndDate, relationshipName)}>Add</button>
									</div>
									<div className="ml-4 mt-5">
										{applications.length ? applications.map((r, index) => {
											const item = r.application

											return <div key={index} className="flex items-center justify-start">
												<p className="mr-4 w-[28%]">{item.name}</p>
												<div>
													<select
														value={r.relationship_dict.id}
														onChange={(e) => applicationRelationshipChangeHandler(r, e)}
														className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
														name="applications"
													>
														{relationship.length &&
															relationship.map((item) => {
																return (
																	<option value={item.id} key={item.id}>
																		{item.name}
																	</option>
																)
														})}
													</select>
												</div>	
												<div className="flex">
													<input
														value={r.start_date}
														onChange={(e) => applicationRelationshipStartDateChangeHandler(r, e)}
														type="date"
														id="end-date"
														name="end-date"
														className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
													/>
													<input
														value={r.end_date}
														onChange={(e) => applicationRelationshipEndDateChangeHandler(r, e)}
														type="date"
														id="end-date"
														name="end-date"
														className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
													/>
													<button onClick={() => applicationRelationshipDeleteHandler(r, applications, setApplications)}><AiOutlineClose /></button>
												</div>
											</div>
										})
										: <p>No connections</p>
									}
									</div>
								</div>
							:
								<button className="form-activity__button-open" onClick={() => {setFormActive(true)}}>Open</button>
							}
						</div>
					</div> */}
					<div className="w-3/4 flex justify-between align-middle">
						<button
							onClick={() => {
								setAddChosen(false)
							}}
							className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
							Cancel
						</button>
						<button
							onClick={addData}
							className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
							Add Process
						</button>
					</div>
				</div>
			)}
			{updateChosen && (
				<div>
					<div className="top-inputs">
						<div>
						<p>Name</p>
							<input
							value={updateName}
							onChange={(e) => setUpdateName(e.target.value)}
							placeholder="Name"
							type="text"
							id="name"
							autoFocus
							name="name"
							className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-0 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-0"}
						/>
						</div>
						<div>
							<p>Process Level</p>
							<select
								value={updateProperty}
								onChange={(e) => setUpdateProperty(e.target.value)}
								className="bg-white h-11 w-72 shadow rounded py-2 px-3"
								name="properties"
								id="properties"
							>
								{capabilityTypeType.map((item) => {
									const empty = item ? false : true
									return (
									<option disabled={empty} value={item.name} key={item.level}>
										{item.name}
									</option>
									)
								})}
							</select>
						</div>
						<div>
						<p>Link</p>
							<input
							value={updateLink}
							onChange={(e) => setUpdateLink(e.target.value)}
							placeholder="Link"
							type="text"
							id="link"
							name="link"
							className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
						/>
						</div>
					</div>
					<div className="top-inputs">
					<div className="1-1/4">
						<p>Start Date</p>
						<input
							value={updateStartDate}
							onChange={(e) => setUpdateStartDate(e.target.value)}
							type="date"
							id="start-date"
							name="start-date"
							className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
						/>
						</div>
						<div className="1-1/4">
						<p>End Date</p>
						<input
							value={updateEndDate}
							onChange={(e) => setUpdateEndDate(e.target.value)}
							type="date"
							id="end-date"
							name="end-date"
							className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
						/>
						</div>
						<div>
						<p>Tag</p>
							<input
							value={updateTag}
							onChange={(e) => setUpdateTag(e.target.value)}
							placeholder="Tag"
							type="text"
							id="tag"
							name="tag"
							className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
						/>
						</div>
					</div>
					{/* <div className="mr-4 mt-6">
						<p>Connected Applications</p>
						<div className="form-application-container">
							{formActive ?
								<div className="h-11 shadow rounded py-2 px-3 form-application-relationship">
									<button className="form-activity__button-close" onClick={() => setFormActive(false)}><span>Close</span><AiOutlineClose /></button>
									<div className="flex items-end m-2">
										<div>
											<p>Application</p>
											<select												
												onChange={applicationOnChangeHandler}
												className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
												name="relationship"
											>
												{application.length && application.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})}
											</select>
										</div>
										<div>
											<p>Relationship</p>
											<select
												value={relationshipName.id}
												onChange={relationshipChangeHandler}
												className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
												name="relationship"
											>
												{relationship.length &&
													relationship.map((item) => {
														return (
															<option value={item.id} key={item.id}>
																{item.name}
															</option>
														)
												})}
											</select>
										</div>	
										<div>
											<p>Start Date</p>
											<input
												value={applicationRelationshipStartDate}
												onChange={(e) => setApplicationRelationshipStartDate(e.target.value)}
												type="date"
												name="relationship-date"
												className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
										<div>
											<p>End Date</p>
											<input
												value={applicationRelationshipEndDate}
												onChange={(e) => setApplicationRelationshipEndDate(e.target.value)}
												type="date"
												name="relationship-date"
												className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
										<button className="text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg" onClick={() => updateApplicationRelationshipAddHandler(applicationObject, applicationRelationshipStartDate, applicationRelationshipEndDate, relationshipName)}>Add</button>
									</div>
									<div className="ml-4 mt-5">
										{updateApplications.length ? updateApplications.map((r, index) => {
												const item = r.application

												return <div key={index} className="flex items-center justify-start">
													<p className="mr-4 w-[28%]">{item.name}</p>
													<div>
														<select
															value={r.relationship_dict.id}
															onChange={(e) => updateApplicationRelationshipChangeHandler(r, e)}
															className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
															name="applications"
														>
															{relationship.length &&
																relationship.map((item) => {
																	return (
																		<option value={item.id} key={item.id}>
																			{item.name}
																		</option>
																	)
															})}
														</select>
													</div>	
													<div className="flex">
														<input
															value={r.start_date}
															onChange={(e) => updateApplicationRelationshipStartDateChangeHandler(r, e)}
															type="date"
															id="end-date"
															name="end-date"
															className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
														/>
														<input
															value={r.end_date}
															onChange={(e) => updateApplicationRelationshipEndDateChangeHandler(r, e)}
															type="date"
															id="end-date"
															name="end-date"
															className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
														/>
														<button onClick={() => updateApplicationRelationshipDeleteHandler(r, updateApplications)}><AiOutlineClose /></button>
													</div>
												</div>
										}): <p>No connections</p>
										}
									</div>
								</div>
							:
								<button className="form-activity__button-open" onClick={() => {setFormActive(true)}}>Open</button>
							}
						</div>
					</div> */}
					<div className="w-3/4 flex justify-between align-middle">
						<button
						onClick={() => {
							setUpdateChosen(false)
						}}
						className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
						Cancel
						</button>
						<button
						onClick={updateData}
						className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
						Update Process
						</button>
					</div>
				</div>
			)}
			<div className="tables">
				<div className="top-table">
					<div className="plus-minus">
						<IconButton
							className="icon-img"
							onClick={() => {
								setUpdateChosen(false);
								setAddChosen(!addChosen);
							}}
							notRestricted={Processes_Add}
							stateActive={addChosen}
							tooltip={'Add new'}
						>
							<AiOutlineFileAdd alt="add" />
						</IconButton>
					</div>
					<div className="plus-minus ml-5">
                    <IconButton
                            className="icon-img"
                            onClick={deleteAllProcesses}
                            notRestricted={Processes_Delete && (business && business.length)}
                            stateActive={showDelete}
                            tooltip={'Delete all table data'}
                        >
                            <MdDeleteForever />
                    </IconButton>
                </div>
				<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
					<div
						className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
						onClick={() => {
						if (current > 0) {
							setCurrent(current - 1);
						}
						}}
					>
						<img src={Left} alt="left" />
					</div>

					<div
						className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
						onClick={() => {
							if (current < paginationLevels - 2) {
								setCurrent(current + 1);
							}
						}}
					>
						<img src={Right} alt="right" />
					</div>
				</div>
				</div>
				<div className="table-div">
				{loading || !fetched ? 
					<SkeletonTable /> :
					<BusinessTable
						data={businessPerPage}
						deletedChosen={false}
						setSelected={null}
						setUpdateChosen={setUpdateChosen}
						setAddChosen={setAddChosen}
						
						setUpdateId={setUpdateId}
						setUpdateName={setUpdateName}
						setUpdateStartDate={setUpdateStartDate}
						setUpdateEndDate={setUpdateEndDate}
						setUpdateProperty={setUpdateProperty}
						setUpdateTag={setUpdateTag}
						setUpdateLink={setUpdateLink}

						propertyType={capabilityTypeType}
						bpRelations={bpRelations}

						current={current}
						setPaginationLevels={setPaginationLevels}

						setShowDelete={setShowDelete}
						setUpdateApplications={setUpdateApplications}

						access={access.Buttons}            
					/>
				}
				</div>
				
			</div>
			</>
		}
		{active === 'Apps 2 Processes' && 
			<BusinessRelations 
				loading={loading} 
				fetched={fetched} 
				application={application} 
				business={business} 
				relationship={relationship} 
				bpRelationsPerPage={bpRelationsPerPage}
				setError={setError}
				setSuccess={setSuccess}
				getBPRelations={getBPRelations}
				newBPRelations={newBPRelations}
				editBPRelations={editBPRelations}
				access={access.Buttons}
			/>
		}
		{active === 'Report' && <ProcessesReport dataArray={business} organization={organization} capabilityType={capabilityType} capabilityTypeType={capabilityTypeType} bpRelations={bpRelations} application={application} access={access.Buttons} setError={setError}/>}
		{active === 'Export' && <BusinessExport dataArray={business} capabilityType={capabilityType} dataArrayBP={bpRelations} dataArrayApp={application} access={access.Buttons} />}
		{active === 'Import' && <BusinessImport dataArray={business} setSuccess={setSuccess} setWarning={setWarning} setError={setError} access={access.Buttons} />}
		</>
	)
}

export default BusinessPage

import React, { useState } from "react"

import BusinessRelationsTable from "../../components/Business/BusinessRelationsTable"

import Add from "../../assets/addCapability.svg"
import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"

import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"

const BusinessRelations = ({ 
	application, 
	business, 
	relationship, 
	newBPRelations, 
	getBPRelations, 
	editBPRelations, 
	loading, 
	fetched, 
	bpRelations, 
	bpRelationsPerPage, 
	setSuccess, 
	setError,
	access
}) => {
	const [addChosen, setAddChosen] = useState(false)
	const [updateChosen, setUpdateChosen] = useState(false)

	const [errorField, setErrorField] = useState('')

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [applications, setApplications] = useState('')
	const [businesses, setBusinesses] = useState('')
	const [relationships, setRelationships] = useState(relationship && relationship[0].name)

	const [updateId, setUpdateId] = useState('')
	const [updateApplications, setUpdateApplications] = useState('')
	const [updateBusinesses, setUpdateBusinesses] = useState('')
	const [updateRelationships, setUpdateRelationships] = useState('')
	const [updateStartDate, setUpdateStartDate] = useState('')
	const [updateEndDate, setUpdateEndDate] = useState('')

	const [current, setCurrent] = useState(0)
	const [paginationLevels, setPaginationLevels] = useState(bpRelationsPerPage.length)

	const clearFields = () => {
		applications('')
		businesses('')
		relationships('')
		setStartDate('')
		setEndDate('')
	}

	const getRelationshipId = (r) => { 
		const relationshipObj = relationship.find(item => item.name === r)
		
		return relationshipObj ? relationshipObj.id : -1
	}

	const getApplicationId = (a) => {
		
		return application.filter(item => item.name === a)[0].id
	}

	const getBusinessId = (b) => { 
		return business.filter(item => item.name === b)[0].id
	}

	const addData = () => {
		if (!businesses) {
			setError("Process is required");
			setErrorField('business')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);
			return
		}
		if (!applications) {
			setError("Application is required");
			setErrorField('application')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);
			return
		}
		newBPRelations({
			application_id: getApplicationId(applications),
			bp_list_id: getBusinessId(businesses),
			relationship_dict_id: getRelationshipId(relationships),
			start_date: startDate,
			end_date: endDate,

		})
		.then(() => {
			setAddChosen(false);
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateBusinesses) {
			setError("Process is required")
			setErrorField('business')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);
			return
		}
		if (!updateApplications) {
			setError("Application is required")
			setErrorField('application')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);
			return
		}
		editBPRelations({
			id: updateId,
			application_id: getApplicationId(updateApplications),
			bp_list_id: getBusinessId(updateBusinesses),
			relationship_dict_id: getRelationshipId(updateRelationships),
			start_date: updateStartDate,
			end_date: updateEndDate,
		})
		.then(() => {
			setSuccess(updateBusinesses + " successfully updated")
			setUpdateChosen(false)
			setTimeout(() => {
			setSuccess("");
			}, 2000);
			getBPRelations();
		})
		.catch((err) => {
			setError(err.message);
			setTimeout(() => {
			setError("");
			}, 2000);
		});
	}

	return (
		<>
		{addChosen && (
			<div>
			<div className="top-inputs">
				<div>
					<p>Process</p>
					<select
						value={businesses}
						onChange={(e) => setBusinesses(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="process"
						id="process"
						autoFocus
					>	
						<option default value={businesses}>
							{businesses}
						</option>
						{business.length > 0 &&
						business.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
				<div>
					<p>Application</p>
					<select
						value={applications}
						onChange={(e) => setApplications(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="application"
						id="application"
					>
						<option default value={applications}>
							{applications}
						</option>
						{application.length > 0 &&
						application.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
				
				<div>
					<p>Relationship</p>
					<select
						value={relationships}
						onChange={(e) => setRelationships(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="relationship"
						id="relationship"
					>
						{relationship.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
			</div>
			<div className="top-inputs">
			<div className="1-1/4">
				<p>Start Date</p>
				<input
					value={startDate}
					onChange={(e) => setStartDate(e.target.value)}
					type="date"
					id="start-date"
					name="start-date"
					className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
				/>
				</div>
				<div className="1-1/4">
					<p>End Date</p>
					<input
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						type="date"
						id="end-date"
						name="end-date"
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
					/>
				</div>				
			</div>
			<div className="w-3/4 flex justify-between align-middle">
				<button
				onClick={() => {
					setAddChosen(false);
				}}
				className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
				>
				Cancel
				</button>
				<button
				onClick={() => {
					addData();
				}}
				className="text-white bg-indigo-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-indigo-600 rounded text-lg"
				>
				Add Relation
				</button>
			</div>
			</div>
		)}
		{updateChosen && (
			<div>
			<div className="top-inputs">
				<div>
					<p>Process</p>
					<select
						value={updateBusinesses}
						onChange={(e) => setUpdateBusinesses(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="process"
						id="process"
					>
						{business.length > 0 &&
						business.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
				<div>
					<p>Application</p>
					<select
						value={updateApplications}
						onChange={(e) => setUpdateApplications(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="application"
						id="application"
					>
						{application.length > 0 &&
						application.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
				<div>
					<p>Relationship</p>
					<select
						value={updateRelationships}
						onChange={(e) => setUpdateRelationships(e.target.value)}
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
						name="relationship"
						id="relationship"
					>
						{relationship.map((item) => {
							return (
							<option value={item.name} key={item.id}>
								{item.name}
							</option>
							)
						})}
					</select>
				</div>
			</div>
			<div className="top-inputs">
			<div className="1-1/4">
				<p>Start Date</p>
				<input
					value={updateStartDate}
					onChange={(e) => setUpdateStartDate(e.target.value)}
					type="date"
					id="start-date"
					name="start-date"
					className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
				/>
				</div>
				<div className="1-1/4">
					<p>End Date</p>
					<input
						value={updateEndDate}
						onChange={(e) => setUpdateEndDate(e.target.value)}
						type="date"
						id="end-date"
						name="end-date"
						className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
					/>
				</div>				
			</div>
			<div className="w-3/4 flex justify-between align-middle">
				<button
				onClick={() => {
					setUpdateChosen(false);
				}}
				className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
				>
				Cancel
				</button>
				<button
				onClick={() => {
					updateData();
				}}
				className="text-white bg-indigo-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-indigo-600 rounded text-lg"
				>
				Update Relation
				</button>
			</div>
			</div>
		)}
		<div className="tables">
			<div className="top-table">
			<div className="plus-minus">
				<div
				className="general cursor-pointer"
				onClick={() => {
					setUpdateChosen(false);
					setAddChosen(!addChosen);
				}}
				>
				<img src={Add} alt="plus" />
				</div>
			</div>
			<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
			<div
				className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
				onClick={() => {
				if (current > 0) {
					setCurrent(current - 1);
				}
				}}
			>
				<img src={Left} alt="left" />
			</div>

			<div
				className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
				onClick={() => {
				if (current < paginationLevels - 2) {
					setCurrent(current + 1);
				}
				}}
			>
				<img src={Right} alt="right" />
			</div>
			</div>
			</div>
			<div className="table-div">
			{loading || !fetched ? 
				<SkeletonTable /> :
				<BusinessRelationsTable
					data={bpRelationsPerPage}
					deletedChosen={false}
					setSelected={null}
					setSuccess={setSuccess}
					setError={setError}
					getData={getBPRelations}
					setUpdateChosen={setUpdateChosen}
					setAddChosen={setAddChosen}
					
					setUpdateId={setUpdateId}
					setUpdateStartDate={setUpdateStartDate}
					setUpdateEndDate={setUpdateEndDate}
					setUpdateApplications={setUpdateApplications}
					setUpdateBusinesses={setUpdateBusinesses}
					setUpdateRelationships={setUpdateRelationships}

					relationship={relationship}
					business={business}
					application={application}

					current={current}
					setPaginationLevels={setPaginationLevels}
					access={access}            
				/>
			}
			</div>
			
		</div>
		</>
	);
};

export default BusinessRelations;
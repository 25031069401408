import React, { useState, useEffect } from "react"

import ScenarioManagement from "../../components/Scenario/ScenarioManagement"
import ScenarioTable from "../../components/Scenario/ScenarioTable"

import SuccessNotice from "../../components/SuccessNotice"
import ErrorNotice from "../../components/ErrorNotice"

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"
import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"
import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs"
import IconButton from "../../components/ui/buttons/IconButton"

import { AiOutlineFileAdd } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"

import ScenarioExport from "../../components/Scenario/ScenarioExport"
import ScenarioImport from "../../components/Scenario/ScenarioImport"
import WarningNotice from "../../components/WarningNotice"
import Modal from "../../components/Modal"


const ScenarioPage = ({ 
	fetched, 
	loading,
	getScenario, 
	newScenario, 
	editScenario,
	getActivity, 
	scenario,
	scenarioPerPage,
	activity,
	successMessage,
	errorMessage,
	clearSuccessMessage,
	clearErrorMessage,
	access
}) => {
	const [active, setActive] = useState('Scenario')
	const [formActive, setFormActive] = useState(false)

	const [success, setSuccess] = useState('')
	const [error, setError] = useState('')
	const [warning, setWarning] = useState('')
	const [errorField, setErrorField] = useState('')

	const [addChosen, setAddChosen] = useState(false)
	const [updateChosen, setUpdateChosen] = useState(false)

	const [showDelete, setShowDelete] = useState(false)
	const [deleteItemsType, setDeleteItemsType] = useState('scenariosArray')

	const [name, setName] = useState('');  
	const [activities, setActivities] = useState('')
	const [activeFlag, setActiveFlag] = useState(false)

	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState('')
	const [updateActivities, setUpdateActivities] = useState('')
	const [updateActiveFlag, setUpdateActiveFlag] = useState('')

	const [activitiesList, setActivitiesList] = useState('')

	const [current, setCurrent] = useState(0)
	const [paginationLevels, setPaginationLevels] = useState(scenarioPerPage.length)

	const {
		Scenarios_Add,
		Scenarios_Edit,
		Scenarios_Delete,
		Scenarios_Previous_page,
		Scenarios_Next_page
	} = access.Buttons

	const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
		return (
			<input style={{marginRight: 5}} type={type} name={name} checked={checked} onChange={onChange} />
		)
	}

	const getActivities = async () => {
		setActivitiesList([{id: '', name: ''}, ...activity])
	}

	const activitiesChangeHandler = (e) => {
		setActivities({...activities, [e.target.name] : e.target.checked })
	}

	const updateActivitiesChangeHandler = (e) => {
		setUpdateActivities({...updateActivities, [e.target.name] : e.target.checked }) 
	}

	useEffect(() => {
		getActivity()
		getScenario()
		getActivities()
	}, [])

	const clearFields = () => {
		setName('')
		setActivities('')
		setActiveFlag('No')
	}

	const deleteAllScenarios = () => {
		setShowDelete(true)
		setDeleteItemsType('scenariosArray')
	}

	const checkedActivities = (itemsObject) => {
		const keys = Object.keys(itemsObject)
		const checkedItems = []
		for (const key of keys) {
			if (itemsObject[key] === true) {
				checkedItems.push(key)
			}
		}
		return checkedItems
	}
	
	const addData = () => {
		if (!name) {
			setError("Name is required");
			setErrorField('name')
			setTimeout(() => {
				setError('')
				setErrorField('')
			}, 2000);
			return
		}
		newScenario({
			name: name,
			list_of_activities: checkedActivities(activities),
			active_flag: (activeFlag === 'Yes'),
		})
		.then(() => {
			setAddChosen(false)
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateName) {
			setError("Name is required");
			setErrorField('name')
			setTimeout(() => {
				setError('')
				setErrorField('')
			}, 2000);
			return
		}

		editScenario({
			id: updateId,
			name: updateName,
			list_of_activities: checkedActivities(updateActivities),
			active_flag: (updateActiveFlag === 'Yes')
		})
		.then(() => {
			setUpdateChosen(false)
			clearFields()
		})
	}
 
	return (
		<>
			<div className="notices">
				{(success || successMessage) && <SuccessNotice clearMessage={clearSuccessMessage} message={success || successMessage} />}
				{warning && <WarningNotice  message={warning} />}
				{(error || errorMessage) && <ErrorNotice clearMessage={clearErrorMessage} message={error || errorMessage} />}
			</div>
			{loading || !fetched ? 
				<SkeletonTabs /> :
				<ScenarioManagement active={active} setActive={setActive} access={access.Tabs} />
			}
			{active === 'Scenario' && 
				<>
				{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteItemType={deleteItemsType}
					deleteItems={scenario}
				/>
				)}
				{addChosen && (
					<div>
					<div className="top-inputs">
						<div>
						<p>Name</p>
							<input
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Name"
							type="text"
							id="name"
							autoFocus
							name="name"
							className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
						/>
						</div>
						<div>
						<p>Activity</p>
						<div className="form-activity-container">
							{formActive ?
							<>
								<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
								<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}>Close</button>
								{activitiesList.map(item => (
									<label key={item.id}><br/>
										<Checkbox name={item.id} checked={activities[item.id]} onChange={activitiesChangeHandler} />
										{item.id + ' ' + item.name}
									</label>
								))}
								</div>
							</>
							:
							<button className="form-activity__button-open" onClick={() => { setFormActive(true) }}>Open</button>
							}
						</div>
						</div>
						<div>
						<p>Active flag</p>
						<select
							value={activeFlag}
							onChange={(e) => setActiveFlag(e.target.value)}
							className="bg-white h-10 w-72 shadow rounded py-2 px-3 ml-4"
							name="active-flag"
							id="active-flag"
						>
							<option value="No" key="No">No</option>
							<option value="Yes" key="Yes">Yes</option>
						</select>
						</div>
					</div>
					<div className="w-3/4 flex justify-between align-middle">
						<button
						onClick={() => {
							setAddChosen(false);
						}}
						className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
						Cancel
						</button>
						<button
						onClick={() => {
							addData();
						}}
						className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
						Add Scenario
						</button>
					</div>
					</div>
				)}
				{updateChosen && (
					<div>
					<div className="top-inputs">
						<div>
						<p>Name</p>
						<input
							value={updateName}
							onChange={(e) => setUpdateName(e.target.value)}
							placeholder="Name"
							type="text"
							id="name"
							name="name"
							autoFocus
							className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
						/>
						</div>
						<div>
						<p>Activity</p>
						<div className="form-activity-container">
							{formActive ?
							<>
								<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
								<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}>Close</button>
								{activitiesList.map(item => (
									<label key={item.id}><br/>
										<Checkbox name={item.id} checked={updateActivities[item.id]} onChange={updateActivitiesChangeHandler} />
										{item.id + ' ' + item.name}
									</label>
								))}
								</div>
							</>
							:
							<button className="form-activity__button-open" onClick={() => { setFormActive(true) }}>Open</button>
							}
						</div>
						</div>
						<div>
						<p>Active flag</p>
						<select
							value={updateActiveFlag}
							onChange={(e) => setUpdateActiveFlag(e.target.value)}
							className="bg-white h-11 w-72 shadow rounded py-2 px-3"
							name="active-flag"
							id="active-flag"
						>
							<option value="No" key="No">No</option>
							<option value="Yes" key="Yes">Yes</option>
						</select>
						</div>
					</div>
					<div className="w-3/4 flex justify-between align-middle">
						<button
						onClick={() => {
							setUpdateChosen(false);
						}}
						className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
						Cancel
						</button>
						<button
						onClick={() => {
							updateData();
						}}
						className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
						Update Scenario
						</button>
					</div>
					</div>
				)}
				<div className="tables">
					<div className="top-table">
						<div className="plus-minus">
							<IconButton
								className="icon-img"
								onClick={() => {
									setUpdateChosen(false);
									setAddChosen(!addChosen);
								}}
								notRestricted={Scenarios_Add}
								stateActive={addChosen}
								tooltip={'Add new'}
							>
								<AiOutlineFileAdd alt="add" />
							</IconButton>
						</div>
						<div className="plus-minus ml-5">
							<IconButton
									className="icon-img"
									onClick={deleteAllScenarios}
									notRestricted={Scenarios_Delete && (scenario && scenario.length)}
									stateActive={showDelete}
									tooltip={'Delete all table data'}
								>
									<MdDeleteForever />
							</IconButton>
						</div>
						<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
							<div
								className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
								onClick={() => {
								if (current > 0) {
									setCurrent(current - 1);
								}
								}}
							>
								<img src={Left} alt="left" />
							</div>

							<div
								className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
								onClick={() => {
								if (current < paginationLevels - 2) {
									setCurrent(current + 1);
								}
								}}
							>
							<img src={Right} alt="right" />
							</div>
						</div>
					</div>
					<div className="table-div">
					{loading || !fetched ? 
						<SkeletonTable /> :
						<ScenarioTable
							data={scenarioPerPage}
							deletedChosen={false}
							setSelected={null}
							setUpdateId={setUpdateId}
							setSuccess={setSuccess}
							setError={setError}
							getData={getScenario}
							setUpdateChosen={setUpdateChosen}
							setAddChosen={setAddChosen}
							
							setUpdateName={setUpdateName}
							setUpdateActivities={setUpdateActivities}
							setUpdateActiveFlag={setUpdateActiveFlag}

							current={current}
							setPaginationLevels={setPaginationLevels}
							
							setShowDelete={setShowDelete}

							access={access.Buttons}
						/>
					}
					</div>
					
				</div>
				</>
			}
			{active === 'Import' && <ScenarioImport dataArray={scenario} access={access.Buttons} setWarning={setWarning} setError={setError} setSuccess={setSuccess} dataArrayActivities={activity} />}
			{active === 'Export' && <ScenarioExport dataArray={scenario} access={access.Buttons} dataArrayActivities={activity} />}
		</>
	)
}

export default ScenarioPage

import React, { useEffect, useState, useRef } from 'react'

import TypeRow from "./TypeRow"
import ModalSave from "../../ModalSaveBP"

import Left from "../../../assets/left.svg"
import Right from "../../../assets/right.svg"
import { GoPlus } from "react-icons/go"
import { TbTemperature, TbZoomInArea, TbZoomOutArea } from "react-icons/tb"
import { BiDownload } from "react-icons/bi"
import { RxDotFilled } from "react-icons/rx"
import { BsFillSquareFill } from "react-icons/bs"
import IconButton from '../../ui/buttons/IconButton'
import OldTimeline from '../../Timeline/OldTimeline'


const ProcessesReport = ({ 
	dataArray, 
	organizationName, 
	setError, 
	activeSet, 
	setActiveSet, 
	setUpdateName, 
	setActive, 
	setUpdateChosen,
	setUpdateLink,
	setUpdateProperty,
	setUpdateStartDate,
	setUpdateEndDate,
	setUpdateId,
	setUpdateFirstLevel,
	setUpdateSecondLevel,
	setUpdateThirdLevel,
	setUpdateSetId,
	setMaturity,
	capabilityType,
	capabilityTypeType,
	application,
	bpRelations,
	organization,
	access
}) => {
	const [modalSaveActive, setModalSaveActive] = useState(false)
	const [toggleBacklog, setToggleBacklog] = useState(false)
	const [toggleHeatmap, setToggleHeatmap] = useState(false)
	const [isHeatmapNotDot, setIsHeatMapDot] = useState(false)

	const myDivRef = useRef(null)
	const [divWidth, setDivWidth] = useState(null)

	const [timeLine, setTimeLine] = useState(new Date().toJSON().slice(0, 10))
	const [timeLineLength, setTimeLineLength] = useState(364)
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 2).toJSON().slice(0, 10))
	const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 32).toJSON().slice(0, 10))
	const [rightDatesData, setRightDatesData] = useState([])

	const [timeoutId, setTimeoutId] = useState(null)


	const handleLongPressInc = () => {
	  setTimeoutId(
		setInterval(() => {
			setTimeLine(prevValue => convertPositionToDate(convertDateToDays(prevValue) + 1))
		}, 100)
	  )
	}

	const handleLongPressDec = () => {
		setTimeoutId(
		  setInterval(() => {
			setTimeLine(prevValue => convertPositionToDate(convertDateToDays(prevValue) - 1))
		  }, 100)
		)
	  }
  
	const handleRelease = () => {
	  if (timeoutId !== null) {
		clearInterval(timeoutId)
		setTimeoutId(null)
	  }
	}

	const findClosestBiggerDate = (targetDate, dateArray) => {
		return dateArray.reduce((closestDate, currentDate) => {
			const target = new Date(targetDate)
			const current = new Date(currentDate)
	
			if (current > target) {
				if (!closestDate || current < closestDate) {
					return currentDate
				}
			}
			
			return closestDate
		}, null)
	}
	

	const nextPin = () => {
		const closestBiggerDate = findClosestBiggerDate(timeLine, pinsDates)
		const pinInView = positionPinInView(closestBiggerDate)
		console.log(pinInView)
		setTimeLine(pinInView)
	}

	const previousPin = () => {
		for (const date of pinsDates) {
			if (date && date < timeLine) {
				setTimeLine(date)
				// const pinInView = positionPinInView(date)
				// setTimeLine(pinInView)
			}
		}
	}

	const [pinsDates, setPinsDates] = useState([])

	const widthValue = JSON.parse(localStorage.getItem('settings-column-width-bcm'))
	const [columnWidth, setColumnWidth] = useState(widthValue ? widthValue.width : 10)
	const [amountElementsOnPage, setAmountElementsOnPage] = useState(10)
	
	const {
		Capabilities_Report_Save_report,
		Capabilities_Report_Toggle_backlog,
		Capabilities_Report_Show_heatmap,
		Capabilities_Report_Show_backlog,
		Capabilities_Report_Select_set,
		Capabilities_Report_Previous_page,
		Capabilities_Report_Next_page,
		Capabilities_Report_Change_start_date,
		Capabilities_Report_Change_current_date,
		Capabilities_Report_Change_end_date 
	} = access
	const columnWidthIncrease = () => {
		if (columnWidth < 50) {
			setColumnWidth(columnWidth => columnWidth + 5)
			localStorage.setItem('settings-column-width-bcm', JSON.stringify({width: columnWidth}))
		}
	}

	const columnWidthDecrease = () => {
		if (columnWidth > 10) {
			setColumnWidth(columnWidth => columnWidth - 5)
			localStorage.setItem('settings-column-width-bcm', JSON.stringify({width: columnWidth}))
		}
	}

	function groupObjectsByDynamicDate(objects, getDateFromObject) {
		return objects.reduce((grouped, object) => {
		  const date = getDateFromObject(object); // Call the provided function to get the date from the object
		  if (!grouped[date]) {
			grouped[date] = [];
		  }
		  grouped[date].push(object);
		  return grouped;
		}, {});
	}
	  
	  
	  // Define a function to extract the date from an object
	function getDateFromObject(object) {
		return object.start_date; // Modify this function based on your object structure
	}

	const activeDatesArray = (dataArray, date) => {
		const result = []
		for (const i of dataArray) {
			if (
				((new Date(date) > new Date(i.start_date)) && (new Date(date) < new Date(i.end_date))) ||       
				((!i.start_date && new Date(i.end_date) > new Date(date)) || (!i.end_date && new Date(i.start_date) < new Date(date))) ||
				(!i.start_date && !i.end_date)
			) {
				result.push(i)
			}
		}
		return result
	}

	const countDays = (sDate, eDate) => {
		const startDate = new Date(sDate)
		const endDate = new Date(eDate)
		// Convert both dates to milliseconds
		const startDateMs = startDate.getTime();
		const endDateMs = endDate.getTime();
	  
		// Calculate the difference in milliseconds
		const diffMs = endDateMs - startDateMs;
	  
		// Convert the difference in milliseconds to days
		const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
	  
		// Return the number of days
		return diffDays;
	}

	const startDateChangeHandler = (e) => {
		const date = e.target.value
		setStartDate(date)
		setTimeLineLength(countDays(date, endDate))
		new Date(date) < new Date(timeLine) && setTimeLine(date)
	}

	const endDateChangeHandler = (e) => {
		setEndDate(e.target.value)
		setTimeLineLength(countDays(startDate, e.target.value))
	}

	const convertDateToDays = (str) => {
		const date = new Date(str)
		const diff = date - new Date(startDate)  //slider position
		const position = Math.floor(diff / (1000 * 60 * 60 * 24)); // convert milliseconds to days and round down
		return position
	}

	const convertPositionToDate = (position) => {
		const newDateMs = new Date(startDate).getTime() + (position * 24 * 60 * 60 * 1000)
		const newDate = new Date(newDateMs)
		
		return newDate.toJSON().slice(0, 10)
	}

	const calculatePinPositions = (pinValue) => {
		const pinPositionRouph = convertDateToDays(pinValue)

		return ((pinPositionRouph + 1) / timeLineLength) * 98
	}

	useEffect(() => {
		const dates = groupObjectsByDynamicDate(dataArray, getDateFromObject)
		const pinsArray = []
		const keys = Object.keys(dates)

		for (const key of keys) {
			pinsArray.push(key)
		}

		pinsArray.sort((a, b) => new Date(a) - new Date(b))
		setPinsDates(pinsArray)

	}, [])

	useEffect(() => {
		const data = activeDatesArray(dataArray, timeLine)
		setRightDatesData(data)
		// data.sort((a, b) => parseFloat(Number('' + a.first_level + a.second_level + a.third_level)) - parseFloat(Number('' + b.first_level + b.second_level + b.third_level)))
		// const groupedArray = groupArrayIntoSixes(firstLevelCaps, amountElementsOnPage)
		
		// setLevels(groupedArray)
		if (myDivRef.current) {
			const width = myDivRef.current.clientWidth
			setDivWidth(width)
		}

	}, [timeLine, dataArray, amountElementsOnPage])

	useEffect(() => {
		if (columnWidth <= 30) setAmountElementsOnPage(3)
		if (columnWidth <= 25) setAmountElementsOnPage(4)
		if (columnWidth <= 20) setAmountElementsOnPage(5)
		if (columnWidth <= 15) setAmountElementsOnPage(6)
		if (columnWidth <= 10) setAmountElementsOnPage(10)
	}, [columnWidth])
	
	const saveActive = () => {
		if (!dataArray.length) {
			setError('Nothing to export')
			setTimeout(() => {
				setError()
			}, 2000)
			return
		}
		setModalSaveActive(!modalSaveActive)
		
	}

	const positionPinInView = (strDate) => {
		if (!strDate) return strDate

		const currentDate = new Date(strDate)
		currentDate.setDate(currentDate.getDate() + 1)
		const isoString = currentDate.toISOString().split('T')[0]

		return isoString
		
	}

	if (!dataArray.length) {
		return (
			<div className="chart-notification">
				<p className="chart-notification__text">No data for that chart</p>
			</div>
		)
	}
	
	return ( 
		<>
			<div className="reports">
				<div className="top-report">
					<div className="plus-minus">
						<IconButton
							className="icon-img"
							onClick={saveActive}
							notRestricted={Capabilities_Report_Save_report}
							stateActive={modalSaveActive}
						>
							<BiDownload />
						</IconButton>
						<IconButton
							className="icon-img"
							onClick={() => {setToggleBacklog(!toggleBacklog)}}
							notRestricted={Capabilities_Report_Toggle_backlog}
							stateActive={toggleBacklog}
						>
							<GoPlus />
						</IconButton>
						<IconButton
							className="icon-img"
							onClick={() => {setToggleHeatmap(!toggleHeatmap)}}
							notRestricted={false}
							stateActive={toggleHeatmap}
						>
							<TbTemperature />
						</IconButton>
					</div>
				</div>
				<div className="capabilities-report-info">
					<p>{organization && organization.name}</p>
					<p>{timeLine}</p>
				</div>
				<div className="capabilities-report" id="chart">
					{ toggleHeatmap &&
						<div className="heatmap-switch">
							<label htmlFor="s1"><RxDotFilled /></label>
							<input id="s1" type="checkbox" className="switch" checked={isHeatmapNotDot} onChange={() => setIsHeatMapDot(!isHeatmapNotDot)}/>
							<label htmlFor="s1"><BsFillSquareFill /></label>
						</div>
					}
					<div className={toggleBacklog ? "capabilities-report__chart flex-col gap-0 overflow-x-scroll" : "capabilities-report__chart  flex-col gap-0 overflow-x-scroll"} id="chart">
						<ModalSave active={modalSaveActive} rightDatesData={rightDatesData} date={timeLine} capabilityTypeType={capabilityTypeType} organizationName={organization && organization.name} application={application} bpRelations={bpRelations} columnWidth={columnWidth} closeModal={() => { setModalSaveActive(false) }} />
						{toggleBacklog && 
							<div className="backlog">
								<TypeRow 
									item={{name: 'Backlog', order: -1}} 
									dataArray={rightDatesData} 
									heatmapActive={toggleHeatmap} 
									timeline={timeLine}
									application={application} 
									bpRelations={bpRelations}
									setUpdateName={setUpdateName} 
									setActive={setActive} 
									setUpdateChosen={setUpdateChosen}
									setUpdateLink={setUpdateLink}
									setUpdateProperty={setUpdateProperty}
									setUpdateStartDate={setUpdateStartDate}
									setUpdateEndDate={setUpdateEndDate}
									setUpdateId={setUpdateId}
									setUpdateFirstLevel={setUpdateFirstLevel}
									setUpdateSecondLevel={setUpdateSecondLevel}
									setUpdateThirdLevel={setUpdateThirdLevel}
									setUpdateSetId={setUpdateSetId}
									setMaturity={setMaturity}
									itemWidth={columnWidth}
								/>
							</div>
						}
						{capabilityTypeType && capabilityTypeType.map(item => {
							return <TypeRow 
										key={item.name} 
										item={item} 
										dataArray={rightDatesData} 
										application={application} 
										bpRelations={bpRelations} 
										itemWidth={columnWidth}
									/>
						})}
					</div>
				</div>
			</div>
			<OldTimeline dataArray={dataArray} setRightDatesData={setRightDatesData} access={access} setReportDate={setTimeLine}/>
		</>
	)
}

export default ProcessesReport
import { handleActions } from "redux-actions"
import groupArrayIntoSixes from "../../handlers/groupArrayHandler"

import { 
	login, 
	signUp,
	editScenario,
	editProduct, 
	editGoal, 
	editGoalType, 
	editActivityType, 
	editActivity, 
	editCapabilityType, 
	editCapability,
	editBusiness,
	editApplication,
	newScenario, 
	newProduct, 
	newGoal, 
	newGoalType, 
	newActivityType, 
	newActivity, 
	newCapabilityType, 
	newCapability, 
	newBusiness,
	newApplication,
	newInvite,
	getScenario, 
	getProduct, 
	getGoal, 
	getGoalType, 
	getActivityType, 
	getActivity, 
	getCapabilityType, 
	getCapability,
	getBusiness,
	getApplication,
	deleteActivity, 
	deleteActivityType, 
	deleteCapability, 
	deleteCapabilityType, 
	deleteGoal, 
	deleteGoalType, 
	deleteProduct, 
	deleteScenario,
	deleteBusiness,
	deleteApplication, 
	getDepartment,
	getOrganization,
	editOrganization,
	getRelationship,
	getBPRelations,
	newBPRelations,
	editBPRelations,
	deleteBPRelations,
	getSet,
	newSet,
	editSet,
	deleteSet,
	getMaturityDict,
	importCapability,
	deleteCapabilities,
	newFeedback,
	newSuccessMessage,
	newErrorMessage,
	newWarningMessage,
	clearSuccessMessage,
	clearErrorMessage,
	clearWarningMessage,
	forgotPassword,
	newPassword,
	getUserRole,
	importGoal,
	importActivity,
	importApplication,
	importBusiness,
	importProduct,
	importScenario,
	newLogout,
	importBPRelations,
	changePassword,
	getUser,
	getAllUsers,
	deleteUser,
	editUser,
	deleteInvite,
	clearBase,
	deleteActivities,
	deleteApplications,
	deleteProcesses,
	deleteGoals,
	deleteProducts,
	deleteScenarios,
	deleteBPRelation,
	getCapabilityTypeType,
	getActiveInvites,
	getPeople,
	newPerson,
	editPerson,
	deletePerson,
	getDepartments,
	newDepartment,
	editDepartment,
	deleteDepartment,
	getTeams,
	newTeam,
	editTeam,
	deleteTeam,
	deleteTeams
} from "../../api/actions"

export const defaultState = {
	token: localStorage.getItem('token'),
	userName: localStorage.getItem('userName'),
	userOrganization: localStorage.getItem('userOrganization'),
	userRole: {role: 'SiteAdmin'},
	user: null,
	allUsers: null,

	fetched: true,
	loading: false,
	successMessage: null,
	errorMessage: null,
	warningMessage: null,

	capability: [],
	capabilityPerPage: [],
	activity: [],
	activityPerPage: [],
	goal: [],
	goalPerPage: [],
	product: [],
	productPerPage: [],
	scenario: [],
	scenarioPerPage: [],
	business: [],
	businessPerPage: [],
	application: [],
	applicationPerPage: [],
	bpRelations: [],
	bpRelationsPerPage: [],
	set: [{name: "-", id: 0, list_name: 'capability'}],
	maturity: [],
	people: [],
	department: [],
	team: [],

	access: {
		Role: "SiteAdmin",
		Menu: {
			Dashboard: true,
			Goals: true,
			Capabilities: true,
			Activities: true,
			Products: true,
			Scenarios: true,
			Processes: true,
			Applications: true,
			Reports: true,
			Settings: true,
			Feedback: true
		},
		Tabs: {
		  Dashboard_All: true,
		  Dashboard_Capabilities: true,
		  Dashboard_Goals: true,
		  Dashboard_Activities: true,
		  Dashboard_Products: true,
		  Dashboard_Scenarios: true,
		  Dashboard_Processes: true,
		  Dashboard_Applications: true,
		  Goals: true,
		  Goals_Report: true,
		  Goals_Import: true,
		  Goals_Export: true,
		  Capabilities: true,
		  Capabilities_Visual_editor: true,
		  Capabilities_Report: true,
		  Capabilities_Import: true,
		  Capabilities_Export: true,
		  Activities: true,
		  Activities_Report: true,
		  Activities_Import: true,
		  Activities_Export: true,
		  Products: true,
		  Products_Report: true,
		  Products_Import: true,
		  Products_Export: true,
		  Scenarios: true,
		  Scenarios_Report: true,
		  Scenarios_Import: true,
		  Scenarios_Export: true,
		  Processes: true,
		  Processes_App_processes: true,
		  Processes_Import: true,
		  Processes_Export: true,
		  Applications: true,
		  Applications_Report: true,
		  Applications_Import: true,
		  Applications_Export: true,
		  Reports_BCM: true,
		  Reports_Goals_tree: true,
		  Reports_PERT: true,
		  Reports_BCM_Levels: true,
		  Settings_Dictionaries: true,
		  Settings_Organization: true,
		  Settings_Account: true,
		  Settings_Security: true,
		  Feedback_Send_a_feedback: true
		},
		Buttons: {
		  Dashboard_All_View_details: true,
		  Dashboard_Capabilities_View_details: true,
		  Dashboard_Goals_View_details: true,
		  Dashboard_Activities_View_details: true,
		  Dashboard_Products_View_details: true,
		  Dashboard_Scenarios_View_details: true,
		  Dashboard_Processes_View_details: true,
		  Dashboard_Applications_View_details: true,
		  Goals_Add: true,
		  Goals_Delete: true,
		  Goals_Edit: true,
		  Goals_Add_child_goal: true,
		  Goals_Add_Previous_page: true,
		  Goals_Add_Next_page: true,
		  Goals_Report_Download_report: true,
		  Capabilities_Add: true,
		  Capabilities_Select_all: true,
		  Capabilities_Delete_all: true,
		  Capabilities_Add_set: true,
		  Capabilities_Edit_set: true,
		  Capabilities_Delete_set: true,
		  Capabilities_Select_set: true,
		  Capabilities_Move_capability_to_another_set: true,
		  Capabilities_Previous_page: true,
		  Capabilities_Next_page: true,
		  Capabilities_Visual_editor_Add_capability: true,
		  Capabilities_Visual_editor_Toggle_backlog: true,
		  Capabilities_Visual_editor_Select_set: true,
		  Capabilities_Visual_editor_Delete_capability: true,
		  Capabilities_Visual_editor_Edit_capability: true,
		  Capabilities_Visual_editor_Restore: true,
		  Capabilities_Visual_editor_Save: true,
		  Capabilities_Visual_editor_Zoom_in: true,
		  Capabilities_Visual_editor_Zoom_out: true,
		  Capabilities_Report_Save_report: true,
		  Capabilities_Report_Toggle_backlog: true,
		  Capabilities_Report_Show_heatmap: true,
		  Capabilities_Report_Show_backlog: true,
		  Capabilities_Report_Select_set: true,
		  Capabilities_Report_Previous_page: true,
		  Capabilities_Report_Next_page: true,
		  Capabilities_Report_Change_start_date: true,
		  Capabilities_Report_Change_current_date: true,
		  Capabilities_Report_Change_end_date: true,
		  Capabilities_Import_Select_set: true,
		  Capabilities_Import_Download_empty_template: true,
		  Capabilities_Import_Browse_file: true,
		  Capabilities_Import_Upload: true,
		  Capabilities_Export_Select_set: true,
		  Capabilities_Export_Download_every_set_toggle: true,
		  Capabilities_Export_Download: true,
		  Activities_Add: true,
		  Activities_Edit: true,
		  Activities_Delete: true,
		  Activities_Previous_page: true,
		  Activities_Next_page: true,
		  Activities_Report_Download_report: true,
		  Products_Add: true,
		  Products_Edit: true,
		  Products_Delete: true,
		  Products_Previous_page: true,
		  Products_Next_page: true,
		  Scenarios_Add: true,
		  Scenarios_Edit: true,
		  Scenarios_Delete: true,
		  Scenarios_Previous_page: true,
		  Scenarios_Next_page: true,
		  Processes_Add: true,
		  Processes_Edit: true,
		  Processes_Delete: true,
		  Processes_Previous_page: true,
		  Processes_Next_page: true,
		  Processes_App_processes_Add: true,
		  Processes_App_processes_Edit: true,
		  Processes_App_processes_Delete: true,
		  Processes_App_processes_Previous_page: true,
		  Processes_App_processes_Next_page: true,
		  Application_Add: true,
		  Application_Edit: true,
		  Application_Delete: true,
		  Application_Previous_page: true,
		  Application_Next_page: true,
		  Reports_BCM_Save_report: true,
		  Reports_BCM_Toggle_backlog: true,
		  Reports_BCM_Show_heatmap: true,
		  Reports_BCM_Show_backlog: true,
		  Reports_BCM_Select_set: true,
		  Reports_BCM_Previous_page: true,
		  Reports_BCM_Next_page: true,
		  Reports_BCM_Change_start_date: true,
		  Reports_BCM_Change_current_date: true,
		  Reports_BCM_Change_end_date: true,
		  Goals_tree_Report_Download_report: true,
		  Reports_PERT_Download_report: true,
		  Settings_Organization_Edit_organization: true,
		  Settings_Organization_Invite: true,
		  Feedback_Send_a_feedback_Screenshot_toggle: true,
		  Feedback_Send_a_feedback_Send: true
		} 
	},
	organization: null,
	activeInvites: [],
	relationship: [
		{id: 1,name: 'Composition', class: 'Structural'},
		{id: 2,name: 'Aggregation', class: 'Structural'},
		{id: 3,name: 'Assignment', class: 'Structural'},
		{id: 4,name: 'Realization', class: 'Structural'},
		{id: 5,name: 'Serving', class: 'Dependency'},
		{id: 6,name: 'Access', class: 'Dependency'},
		{id: 7,name: 'Influence', class: 'Dependency'},
		{id: 8,name: 'Association', class: 'Dependency'},
		{id: 9,name: 'Triggering', class: 'Dynamic'},
		{id: 10,name: 'Flow', class: 'Dynamic'},
		{id: 11,name: 'Specialization', class: 'Other'}
	],
	capabilityType: ['', 'Support', 'MDM', 'ESG', 'Strategic', 'Planning', 'Execution', 'Learning'],
	capabilityTypeType: [
		{"name": "Strategic", "order": 0},
		{"name": "Planning", "order": 1},
		{"name": "Execution", "order": 2},
		{"name": "Learning", "order": 3},
		{"name": "Support", "order": 4},
		{"name": "MDM", "order": 5},
		{ "name": "ESG", "order": 6}
	],
	activityType: [
		{id: 1, name: 'Initiative'}, 
		{id: 2, name: 'Project'}, 
		{id: 3, name: 'Task'}
	],
	goalType: [
		{id: 1, name: 'Strategy', level: 0},
		{id: 2, name: 'Strategic intention', level: 1}, 
		{id: 3, name: 'Strategic goal', level: 2}, 
		{id: 4, name: 'Business goal', level: 3}, 
		{id: 5, name: 'Business objective', level: 4}, 
		{id: 6, name: 'Project goal', level: 5}, 
		{id: 7, name: 'Sprint goal', level: 6}, 
		{id: 8, name: 'Task goal', level: 7}
	]
}
const reqHandler = (state) => {
	return {
		...state,
		loading: true,
		fetched: false,
	}
}

const resFail = (state, response) => {
	// console.log(response)    
	if (!response || !response.error || !response.error.response) {
		return {
			...state,
			loading: false,
			fetched: true,
			warningMessage: null,
			successMessage: null,
			errorMessage: 'Something went wrong, please try again later'
		}
	}

	const {data} = response.error.response
	return {
		...state,
		loading: false,
		fetched: true,
		warningMessage: null,
		successMessage: null,
		errorMessage: data.message
	}
}

const resSuccess = (state, successMessage = 'Success', fields) => {
	return {
		...state,
		loading: false,
		fetched: true,
		successMessage,
		...fields
	}
}

export const reducers = handleActions({
	[newSuccessMessage]: reqHandler,
	[newSuccessMessage.fail]: resFail,
	[newSuccessMessage]:(state, {payload}) => resSuccess(state, payload.message),

	[newErrorMessage]: reqHandler,
	[newErrorMessage.fail]: resFail,
	[newErrorMessage]:(state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: payload.message,
		}
	},
	[newWarningMessage]: reqHandler,
	[newWarningMessage.fail]: resFail,
	[newWarningMessage]:(state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			warningMessage: payload.message
		}
	},
	[clearSuccessMessage]: reqHandler,
	[clearSuccessMessage.fail]: resFail,
	[clearSuccessMessage]:(state) => resSuccess(state, null),

	[clearErrorMessage]: reqHandler,
	[clearErrorMessage.fail]: resFail,
	[clearErrorMessage]:(state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
		}
	},
	[clearWarningMessage]: reqHandler,
	[clearWarningMessage.fail]: resFail,
	[clearWarningMessage]:(state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			warningMessage: null
		}
	},
	[clearBase]: reqHandler,
	[clearBase.fail]: resFail,
	[clearBase.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Database successfully cleared',
			capability: [],
			capabilityPerPage: [],
			activity: [],
			activityPerPage: [],
			goal: [],
			goalPerPage: [],
			product: [],
			productPerPage: [],
			scenario: [],
			scenarioPerPage: [],
			business: [],
			businessPerPage: [],
			application: [],
			applicationPerPage: [],
			bpRelations: [],
			bpRelationsPerPage: [],
			set: [{name: "-", id: 0, list_name: 'capability'}],
			maturity: [],
		}
	},
	[editUser]: reqHandler,
	[editUser.fail]: resFail,
	[editUser.success]: (state, {payload}) => resSuccess(state, 'Information succesfully updateddd', {user: payload.data}),
	[deleteUser]: reqHandler,
	[deleteUser.fail]: resFail,
	[deleteUser.success]: (state, {payload}) => {
		const remainedUsers = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Successfully deleted',
			allUsers: remainedUsers
		}
	},
	[getAllUsers]: reqHandler,
	[getAllUsers.fail]: resFail,
	[getAllUsers.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			allUsers: payload.data.data
		}
	},
	[getActiveInvites]: reqHandler,
	[getActiveInvites.fail]: resFail,
	[getActiveInvites.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			activeInvites: payload.data
		}
	},
	[getUser]: reqHandler,
	[getUser.fail]: resFail,
	[getUser.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			user: payload.data.data
		}
	},
	[getTeams]: reqHandler,
	[getTeams.fail]: resFail,
	[getTeams.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			team: payload.data.data
		}
	},
	[newTeam]: reqHandler,
	[newTeam.fail]: resFail,
	[newTeam.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			team: payload.data.data,
		}
	},
	[editTeam]: reqHandler,
	[editTeam.fail]: resFail,
	[editTeam.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			team: payload.data.data,
		}
	},
	[deleteTeam]: reqHandler,
	[deleteTeam.fail]: resFail,
	[deleteTeam.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			team: payload.data.data,
		}
	},
	[deleteTeams]: reqHandler,
	[deleteTeams.fail]: resFail,
	[deleteTeams.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			team: payload.data.data
		}
	},
	[getPeople]: reqHandler,
	[getPeople.fail]: resFail,
	[getPeople.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			people: payload.data.data
		}
	},
	[newPerson]: reqHandler,
	[newPerson.fail]: resFail,
	[newPerson.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			people: payload.data.data,
		}
	},
	[editPerson]: reqHandler,
	[editPerson.fail]: resFail,
	[editPerson.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			people: payload.data.data,
		}
	},
	[deletePerson]: reqHandler,
	[deletePerson.fail]: resFail,
	[deletePerson.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			people: payload.data.data,
		}
	},
	[getDepartments]: reqHandler,
	[getDepartments.fail]: resFail,
	[getDepartments.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			department: payload.data.data
		}
	},
	[newDepartment]: reqHandler,
	[newDepartment.fail]: resFail,
	[newDepartment.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			department: payload.data.data,
		}
	},
	[editDepartment]: reqHandler,
	[editDepartment.fail]: resFail,
	[editDepartment.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			department: payload.data.data,
		}
	},
	[deleteDepartment]: reqHandler,
	[deleteDepartment.fail]: resFail,
	[deleteDepartment.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			department: payload.data.data,
		}
	},
	[getUserRole]: reqHandler,
	[getUserRole.fail]: resFail,
	[getUserRole.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			userRole: payload.data
		}
	},
	[login]: reqHandler,
	[login.fail]: resFail,
	[login.success]: (state, {payload}) => {
		localStorage.setItem('token', payload.data.token)
		localStorage.setItem('userName', payload.data.user.email)
		localStorage.setItem('userOrganization', payload.data.user.organization_id)
		return {
			...state,
			loading: false,
			fetched: true,
			token: payload.data.token,
			userName: payload.data.user.email,
			userOrganization: payload.data.user.organization_id
		}
	},
	[signUp]: reqHandler,
	[signUp.fail]: resFail,
	[signUp.success]: (state, {payload}) => {
		localStorage.setItem('token', payload.data.token)
		localStorage.setItem('userName', payload.data.user.email)
		localStorage.setItem('userOrganization', payload.data.user.organization_id)
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Successfully signed up',
			token: payload.data.token,
			userName: payload.data.user.email,
			userOrganization: payload.data.user.organization_id
		}
	},
	[changePassword]: reqHandler,
	[changePassword.fail]: resFail,
	[changePassword.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Password has been updated'
		}
	},
	[forgotPassword]: reqHandler,
	[forgotPassword.fail]: resFail,
	[forgotPassword.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Check your email'
		}
	},
	[newPassword]: reqHandler,
	[newPassword.fail]: resFail,
	[newPassword.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Password has been updated'
		}
	},
	[newLogout]: reqHandler,
	[newLogout.fail]: resFail,
	[newLogout.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
		}
	},
	[deleteInvite]: reqHandler,
	[deleteInvite.fail]: resFail,
	[deleteInvite.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Successfully deleted'
		}
	},
	[newInvite]: reqHandler,
	[newInvite.fail]: resFail,
	[newInvite.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Invite was successfully sent'
		}
	},
	[newFeedback]: reqHandler,
	[newFeedback.fail]: resFail,
	[newFeedback.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			message: 'Your feedback was sent. Thank you for your response!'
		}
	},
	[deleteScenarios]: reqHandler,
	[deleteScenarios.fail]: resFail,
	[deleteScenarios.success]: (state, {payload}) => {
		const scenario = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			scenario,
			scenarioPerPage: groupArrayIntoSixes(scenario, 20)
		}
	},
	[deleteProducts]: reqHandler,
	[deleteProducts.fail]: resFail,
	[deleteProducts.success]: (state, {payload}) => {
		const product = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			successMessage: 'Succesfully deleted',
			product,
			productsPerPage: groupArrayIntoSixes(product, 20)
		}
	},
	[deleteGoals]: reqHandler,
	[deleteGoals.fail]: resFail,
	[deleteGoals.success]: (state, {payload}) => {
		const goal = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			goal,
			goalPerPage: groupArrayIntoSixes(goal, 20)
		}
	},
	[deleteProcesses]: reqHandler,
	[deleteProcesses.fail]: resFail,
	[deleteProcesses.success]: (state, {payload}) => {
		const business = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			business,
			businessPerPage: groupArrayIntoSixes(business, 20)
		}
	},
	[deleteApplications]: reqHandler,
	[deleteApplications.fail]: resFail,
	[deleteApplications.success]: (state, {payload}) => {
		const application = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			application,
			applicationPerPage: groupArrayIntoSixes(application, 20)
		}
	},
	[deleteActivities]: reqHandler,
	[deleteActivities.fail]: resFail,
	[deleteActivities.success]: (state, {payload}) => {
		const activity = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			activity,
			activityPerPage: groupArrayIntoSixes(activity, 20)
		}
	},
	[deleteBPRelations]: reqHandler,
	[deleteBPRelations.fail]: resFail,
	[deleteBPRelations.success]: (state, {payload}) => {
		const bpRelations = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			bpRelations,
			bpRelationsPerPage: groupArrayIntoSixes(bpRelations, 20)
		}
	},
	[deleteCapabilities]: reqHandler,
	[deleteCapabilities.fail]: resFail,
	[deleteCapabilities.success]: (state, {payload}) => {
		const capability = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			capability,
			capabilityPerPage: groupArrayIntoSixes(capability, 20)
		}
	},
	[importBPRelations]: reqHandler,
	[importBPRelations.fail]: resFail,
	[importBPRelations.success]: (state, {payload}) => {
		const bpRelations = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			bpRelations: bpRelations,
			bpRelationsPerPage: groupArrayIntoSixes(bpRelations, 20)
		}
	},
	[importGoal]: reqHandler,
	[importGoal.fail]: resFail,
	[importGoal.success]: (state, {payload}) => {
		const goals = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			goal: goals,
			goalPerPage: groupArrayIntoSixes(goals, 20)
		}
	},
	[importActivity]: reqHandler,
	[importActivity.fail]: resFail,
	[importActivity.success]: (state, {payload}) => {
		const activities = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			activity: activities,
			activityPerPage: groupArrayIntoSixes(activities, 20)
		}
	},
	[importApplication]: reqHandler,
	[importApplication.fail]: resFail,
	[importApplication.success]: (state, {payload}) => {
		const applications = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			application: applications,
			applicationPerPage: groupArrayIntoSixes(applications, 20)
		}
	},
	[importBusiness]: reqHandler,
	[importBusiness.fail]: resFail,
	[importBusiness.success]: (state, {payload}) => {
		const businesses = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			business: businesses,
			businessPerPage: groupArrayIntoSixes(businesses, 20)
		}
	},
	[importProduct]: reqHandler,
	[importProduct.fail]: resFail,
	[importProduct.success]: (state, {payload}) => {
		const products = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			product: products,
			productPerPage: groupArrayIntoSixes(products, 20)
		}
	},
	[importScenario]: reqHandler,
	[importScenario.fail]: resFail,
	[importScenario.success]: (state, {payload}) => {
		const scenarios = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			scenario: scenarios,
			scenarioPerPage: groupArrayIntoSixes(scenarios, 20)
		}
	},
	[importCapability]: reqHandler,
	[importCapability.fail]: resFail,
	[importCapability.success]: (state, {payload}) => {
		const caps = payload.data.data ? payload.data.data : [] 
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Imported',
			capability: caps,
			capabilityPerPage: groupArrayIntoSixes(caps, 20)
		}
	},
	[getMaturityDict]: reqHandler,
	[getMaturityDict.fail]: resFail,
	[getMaturityDict.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			maturity: payload.data,
		}
	},
	[getSet]: reqHandler,
	[getSet.fail]: resFail,
	[getSet.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			set: [{name: "-", id: 0,  list_name: 'capability'}, ...payload.data],
		}
	},
	[newSet]: reqHandler,
	[newSet.fail]: resFail,
	[newSet.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			set: [{name: "-", id: 0, list_name: 'capability'}, ...payload.data.data],
		}
	},
	[editSet]: reqHandler,
	[editSet.fail]: resFail,
	[editSet.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			set: [{name: "-", id: 0, list_name: 'capability'}, ...payload.data.data],
		}
	},
	[deleteSet]: reqHandler,
	[deleteSet.fail]: resFail,
	[deleteSet.success]: (state, {payload}) => {
		const setResp = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			set: [{name: "-", id: 0, list_name: 'capability'}, ...setResp],
		}
	},
	[getBPRelations]: reqHandler,
	[getBPRelations.fail]: resFail,
	[getBPRelations.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			bpRelations: payload.data,
			bpRelationsPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newBPRelations]: reqHandler,
	[newBPRelations.fail]: resFail,
	[newBPRelations.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			bpRelations: payload.data.data,
			bpRelationsPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editBPRelations]: reqHandler,
	[editBPRelations.fail]: resFail,
	[editBPRelations.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully edited',
			bpRelations: payload.data,
			bpRelationsPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[deleteBPRelation]: reqHandler,
	[deleteBPRelation.fail]: resFail,
	[deleteBPRelation.success]: (state, {payload}) => {
		const bpr = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Succesfully deleted',
			bpRelations: bpr,
			bpRelationsPerPage: groupArrayIntoSixes(bpr, 20)
		}
	},
	[getRelationship]: reqHandler,
	[getRelationship.fail]: resFail,
	[getRelationship.success]: (state, {payload}) => {
		const bpr = payload.data ? payload.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			relationship: bpr,
			bpRelationsPerPage: groupArrayIntoSixes(bpr, 20)
		}
	},
	[getOrganization]: reqHandler,
	[getOrganization.fail]: resFail,
	[getOrganization.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			organization: payload.data,
		}
	},
	[editOrganization]: reqHandler,
	[editOrganization.fail]: resFail,
	[editOrganization.success]: (state) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'Information successfully updated'
		}
	},
	[getDepartment]: reqHandler,
	[getDepartment.fail]: resFail,
	[getDepartment.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			department: payload.data,
		}
	},
	[getBusiness]: reqHandler,
	[getBusiness.fail]: resFail,
	[getBusiness.success]: (state, {payload}) => {
		const business = payload.data ? payload.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			business,
			businessPerPage: groupArrayIntoSixes(business, 20)
		}
	},
	[newBusiness]: reqHandler,
	[newBusiness.fail]: resFail,
	[newBusiness.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage:'The process has been added',
			business: payload.data.data,
			businessPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editBusiness]: reqHandler,
	[editBusiness.fail]: resFail,
	[editBusiness.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The process has been updated',
			business: payload.data.data,
			businessPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteBusiness]: reqHandler,
	[deleteBusiness.fail]: resFail,
	[deleteBusiness.success]: (state, {payload}) => {
		const business = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The process has been deleted',
			business,
			businessPerPage: groupArrayIntoSixes(business, 20)
		}
	},
	[getApplication]: reqHandler,
	[getApplication.fail]: resFail,
	[getApplication.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			application: payload.data,
			applicationPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newApplication]: reqHandler,
	[newApplication.fail]: resFail,
	[newApplication.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			application: payload.data.data,
			successMessage: 'The application has been added',
			applicationPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editApplication]: reqHandler,
	[editApplication.fail]: resFail,
	[editApplication.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The application has been updated',
			application: payload.data.data,
			applicationPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteApplication]: reqHandler,
	[deleteApplication.fail]: resFail,
	[deleteApplication.success]: (state, {payload}) => {
		const application = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The application has been deleted',
			application,
			applicationPerPage: groupArrayIntoSixes(application, 20)
		}
	},
	[getScenario]: reqHandler,
	[getScenario.fail]: resFail,
	[getScenario.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			scenario: payload.data,
			scenarioPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newScenario]: reqHandler,
	[newScenario.fail]: resFail,
	[newScenario.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The scenario has been added',
			scenario: payload.data.data,
			scenarioPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editScenario]: reqHandler,
	[editScenario.fail]: resFail,
	[editScenario.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			scenario: payload.data.data,
			successMessage: 'The scenario has been updated',
			scenarioPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteScenario]: reqHandler,
	[deleteScenario.fail]: resFail,
	[deleteScenario.success]: (state, {payload}) => {
		const scenario = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The scenario has been deleted',
			scenario,
			scenarioPerPage: groupArrayIntoSixes(scenario, 20)
		}
	},
	[getProduct]: reqHandler,
	[getProduct.fail]: resFail,
	[getProduct.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			product: payload.data,
			productPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newProduct]: reqHandler,
	[newProduct.fail]: resFail,
	[newProduct.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			product: payload.data.data,
			successMessage: 'The product has been added',
			productPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editProduct]: reqHandler,
	[editProduct.fail]: resFail,
	[editProduct.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The product has been updated',
			product: payload.data.data,
			productPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteProduct]: reqHandler,
	[deleteProduct.fail]: resFail,
	[deleteProduct.success]: (state, {payload}) => {
		const product = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The product has been deleted',
			product,
			productPerPage: groupArrayIntoSixes(product, 20)
		}
	},
	[getGoal]: reqHandler,
	[getGoal.fail]: resFail,
	[getGoal.success]: (state, {payload}) => {
		const goal = payload.data ? payload.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			goal: goal,
			goalPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newGoal]: reqHandler,
	[newGoal.fail]: resFail,
	[newGoal.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The goal has been added',
			goal: payload.data.data,
			goalPerPage: groupArrayIntoSixes(payload.data.data, 20),
		}
	},
	[editGoal]: reqHandler,
	[editGoal.fail]: resFail,
	[editGoal.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The goal has been updated',
			goal: payload.data.data,
			goalPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteGoal]: reqHandler,
	[deleteGoal.fail]: resFail,
	[deleteGoal.success]: (state, {payload}) => {
		const goal = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The goal has been deleted',
			goal,
			goalPerPage: groupArrayIntoSixes(goal, 20)
		}
	},
	[getActivity]: reqHandler,
	[getActivity.fail]: resFail,
	[getActivity.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			activity: payload.data,
			activityPerPage: groupArrayIntoSixes(payload.data, 20)
		}
	},
	[newActivity]: reqHandler,
	[newActivity.fail]: resFail,
	[newActivity.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The activity has been added',
			activity: payload.data.data,
			activityPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[editActivity]: reqHandler,
	[editActivity.fail]: resFail,
	[editActivity.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The activity has been updated',
			activity: payload.data.data,
			activityPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteActivity]: reqHandler,
	[deleteActivity.fail]: resFail,
	[deleteActivity.success]: (state, {payload}) => {
		const activity = payload.data?.data ? payload.data?.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The activity has been deleted',
			activity,
			activityPerPage: groupArrayIntoSixes(activity, 20)
		}
	},
	[getCapability]: reqHandler,
	[getCapability.fail]: resFail,
	[getCapability.success]: (state, {payload}) => {
		const caps = payload.data ? payload.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			capability: caps,
			capabilityPerPage: groupArrayIntoSixes(caps, 20)
		}
	},
	[newCapability]: reqHandler,
	[newCapability.fail]: resFail,
	[newCapability.success]: (state, {payload}) => {
		const caps = payload.data.data === null ? [] : payload.data.data
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			successMessage: 'The capability has been added',
			capability: caps,
			capabilityPerPage: groupArrayIntoSixes(caps, 20)
		}
	},
	[editCapability]: reqHandler,
	[editCapability.fail]: resFail,
	[editCapability.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The capability has been updated',
			capability: payload.data.data,
			capabilityPerPage: groupArrayIntoSixes(payload.data.data, 20)
		}
	},
	[deleteCapability]: reqHandler,
	[deleteCapability.fail]: resFail,
	[deleteCapability.success]: (state, {payload}) => {
		const caps = payload.data.data ? payload.data.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			capability: caps,
			successMessage: 'The capability has been deleted',
			capabilityPerPage: groupArrayIntoSixes(caps, 20)
		}
	},
	[getCapabilityType]: reqHandler,
	[getCapabilityType.fail]: resFail,
	[getCapabilityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			capabilityType: payload.data.property
		}
	},
	[getCapabilityTypeType]: reqHandler,
	[getCapabilityTypeType.fail]: resFail,
	[getCapabilityTypeType.success]: (state, {payload}) => {
		const type = payload.data ? payload.data : []
		return {
			...state,
			loading: false,
			fetched: true,
			capabilityTypeType: type
		}
	},
	[newCapabilityType]: reqHandler,
	[newCapabilityType.fail]: resFail,
	[newCapabilityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been added',
			capability: payload.data
		}
	},
	[editCapabilityType]: reqHandler,
	[editCapabilityType.fail]: resFail,
	[editCapabilityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been updated',
			capability: payload.data
		}
	},
	[deleteCapabilityType]: reqHandler,
	[deleteCapabilityType.fail]: resFail,
	[deleteCapabilityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been deleted',
			capability: payload.data
		}
	},
	[getActivityType]: reqHandler,
	[getActivityType.fail]: resFail,
	[getActivityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			activityType: payload.data
		}
	},
	[newActivityType]: reqHandler,
	[newActivityType.fail]: resFail,
	[newActivityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been added',
			activityType: payload.data
		}
	},
	[editActivityType]: reqHandler,
	[editActivityType.fail]: resFail,
	[editActivityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			errorMessage: null,
			successMessage: 'The type has been updated',
			activityType: payload.data
		}
	},
	[deleteActivityType]: reqHandler,
	[deleteActivityType.fail]: resFail,
	[deleteActivityType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been deleted',
			activityType: payload.data
		}
	},
	[getGoalType]: reqHandler,
	[getGoalType.fail]: resFail,
	[getGoalType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			goalType: payload.data
		}
	},
	[newGoalType]: reqHandler,
	[newGoalType.fail]: resFail,
	[newGoalType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been added',
			goalType: payload.data
		}
	},
	[editGoalType]: reqHandler,
	[editGoalType.fail]: resFail,
	[editGoalType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been updated',
			goalType: payload.data
		}
	},
	[deleteGoalType]: reqHandler,
	[deleteGoalType.fail]: resFail,
	[deleteGoalType.success]: (state, {payload}) => {
		return {
			...state,
			loading: false,
			fetched: true,
			successMessage: 'The type has been updated',
			goalType: payload.data
		}
	},
}
, defaultState)